.sort{
    margin-left: 20px;
    margin-right: 20px;
}

#sortBarContainer{
    display: flex;
    justify-content: center;
    margin-right: 20px;
    margin-left: 20px;
}

.sliderContainer {
    display: flex;
    flex-direction: row;
    width: 40rem;

}

.slider-1 {
    margin-right: 10px;
    width: 10rem;
}

.slider-2 {
    margin-left: 10px;
    width: 10rem;
}