.graph{
    margin-left: 20px;
    margin-right: 20px;
}

#graphBoxContainer{
    display: flex;
    justify-content: center;
}

.legendText{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    font-size: 20px;
    color: #1976d2;
}

.legendContent{
    display: flex;
}

.legendBox{
    width: 5px;
    height: 5px;
    padding: 16px;
}

@media screen and (max-width: 1911px) {
    .legendBox {
        width: 3px;
        height: 3px;
        padding: 10px;
    }
}

@media screen and (max-height: 1000px) {
    .legendBox {
        width: 3px;
        height: 3px;
        padding: 10px;
    }
}