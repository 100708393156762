.wall{
    -webkit-animation: pulse 0.5s 1;
    animation: pulse 0.5s 1;
    background-color: #414141;
    box-shadow: inset 0px 0px 0px 0.2px rgb(206, 82, 82);
}

@keyframes pulse {
    0% {
        transform: scale(0.5);
        background-color: rgb(206, 82, 82);
    }
    75% {
        transform: scale(1.3);
    }
    100% {
        background-color: #414141;
    }
}

.starting{
    animation: pulse-start 0.5s 1;
    background-color: #BFE45D;
    box-shadow: inset 0px 0px 0px 0.2px #bbff00;
}

@keyframes pulse-start {
    0% {
        transform: scale(0.5);
        background-color: #cbdb9d;
    }
    75% {
        transform: scale(1.3);
    }
    100% {
        background-color: #BFE45D;
    }
}

.target{
    animation: pulse-target 0.5s, 1;
    background-color: #D436CA;
    box-shadow: inset 0px 0px 0px 0.2px #ff00ee;
}

@keyframes pulse-target {
    0% {
        transform: scale(0.5);
        background-color: #ce81c9;
    }
    75% {
        transform: scale(1.3);
    }
    100% {
        background-color: #D436CA;
    }
}
.searching {
    -webkit-animation: search_animation 0.5s, 1;
    animation: search_animation 0.5s, 1;
    background-color: #E19223;
    box-shadow: inset 0px 0px 0px 0.2px #ff9500;
}

@keyframes search_animation {
    0% {
        transform: scale(0.1);
        border-radius: 100%;
        background-color: rgb(255, 0, 255);
    }
    50% {
        background-color: rgb(202, 219, 43);
    }
    75% {
        transform: scale(1.3);
        border: #3cff0088;
    }
    100% {
        transform: scale(1);
        background-color: #3cff0088;
    }
}

.path{
    -webkit-animation: path_animation 0.5s;
    animation: path_animation 0.5s;
    animation-direction: alternate;
    background-color: #4D57D2;
    box-shadow: inset 0px 0px 0px 0.2px #0015ff;
}

@keyframes path_animation {
    0% {
        transform: scale(0.1);
        border-radius: 100%;
        background-color: yellowgreen;
    }
    50% {
        background-color: mediumseagreen;
    }
    75% {
        transform: scale(1.3);
    }
    100% {
        background-color: #4D57D2;
    }
}

.box{
    height: 5px;
    width: 5px;
    padding: 16px;
}

@media screen and (max-width: 1911px) {
    .box{
        height: 3px;
        width: 3px;
        padding: 10px;
    }
}

@media screen and (max-height: 1000px) {
    .box{
        height: 3px;
        width: 3px;
        padding: 10px;
    }
}